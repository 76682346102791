import styled from "styled-components";

export const CarouselContainer = styled.section`
  display: flex;
  margin-top: 180px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #EDF0F2;
`;

export const GifImage = styled.img`
  width: 100%;
  max-width: 1600px;
  height: auto;
  margin-bottom: 10px;
  padding: 28px 28px 0 28px;
  object-fit: contain;
  display: block;
`;

export const BoxText = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SlideText = styled.div`
  width: 90%;
  padding: 16px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #363E46;

  @media (max-width: 1024px) {
    width: 100%;
    font-size: 16px;
  }
`;

export const HighlightText = styled.span`
  background-color: rgba(52, 168, 83, 0.6);
  padding: 0 2px;
`;
