import React, { useEffect } from "react";
import Carousel from "../../../../components/library/Caroussel";
import GifMedtrack from "../../../../../../assets/images/carousel-login/slide-flashcard.gif"
import GifSimulados from "../../../../../../assets/images/carousel-login/slide-medtrack.gif"
import GifFlashcards from "../../../../../../assets/images/carousel-login/slide-simulados.gif"
import { CarouselContainer, SlideText, HighlightText, GifImage, BoxText } from "./styles";

const CarouselLogin = () => {
  const slides = [
    {
      gif: GifFlashcards,
      text: (
        <SlideText>
          Reforce o seu aprendizado com os FlashCards. Fácil e interativo <HighlightText>perfeito para estudar em qualquer lugar.</HighlightText>
        </SlideText>
      ),
    },
    {
      gif: GifSimulados,
      text: (
        <SlideText>
          Treinamento intenso e focado em questões com os Simulados, <HighlightText>mais de 58 mil questões comentadas</HighlightText> em vídeo e texto.
        </SlideText>
      ),
    },
    {
      gif: GifMedtrack,
      text: (
        <SlideText>
          <HighlightText>Trilhas de estudos 100% personalizadas com o Medtrack</HighlightText>, ajuste de acordo com sua rotina e chegue preparado para as provas.
        </SlideText>
      ),
    },
  ];

  return (
    <CarouselContainer>
      <Carousel
        isFrial={true}
        showPagination
        pagination={{
          clickable: true,
        }}
        animation="fade left"
        autoplay={{ delay: 4000 }}
        loop
      >
        {slides.map((slide, index) => (
          <div key={index}>
            <GifImage src={slide.gif} alt={`GIF Slide ${index}`} />
            <BoxText>
              {slide.text}
            </BoxText>
          </div>
        ))}
      </Carousel>
    </CarouselContainer>
  );
};

export default CarouselLogin;
