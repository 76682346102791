import * as Dialog from '@radix-ui/react-dialog';
import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { colors } from '../../../../components/library/theme';
import { Infobox } from '../Infobox';
import { Slider } from '../Slider';
import {
  ButtonContainer,
  CloseButton,
  DialogContent,
  DialogOverlay,
  FrialDescriptionBox,
  Hr,
  ModalHeader,
  SliderWrapper,
  StyledEMRButton,
  StyledSpinner,
  TextWrapper,
} from './styles';
import { FrialTimeAvailabilityImage } from './FrialTimeAvailabilityImage';
import { IoLockClosed } from "react-icons/io5";
import { MedTrackTooltip } from '../MedTrackTooltip';
import { trackClickEvents } from '../../../../../services/mixpanel/events/trackClickEvents';

export function TimeAvailability({
  isFrial,
  guruUrl,
  completeGuruUrl,
  availableStudyTime,
  onChangeAvailableTime,
  onGetAvailableTime,
  onUpdateAvailableTime,
  isAvailableTimeModalOpen,
  setIsAvailableTimeModalOpen,
  isAvailableTimeLoading,
}) {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const isLoading = isAvailableTimeLoading || isLoadingData;
  const hasUpdatedAt = availableStudyTime?.updatedAt;
  const hasNextDateToUpdate = availableStudyTime?.nextDateToUpdate;

  const updatedAt =
    hasUpdatedAt &&
    new Date(availableStudyTime.updatedAt).toLocaleDateString('pt-br', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

  const nextDateToUpdate =
    hasNextDateToUpdate &&
    new Date(availableStudyTime.nextDateToUpdate).toLocaleDateString('pt-br', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

  function handleRedirectToCheckout() {
    if (guruUrl) {
      trackClickEvents({
        eventName: "Clique em Definir disponibilidade frial",
        content: {
          UrlGuru: completeGuruUrl,
          Local: `Modal de definir disponibilidade de tempo frial`,
          Error: false,
        },
      });
      window.open(completeGuruUrl, "_self");
    }
  }

  useEffect(() => {
    if (!availableStudyTime) {
      onGetAvailableTime();
    }
    setIsLoadingData(false);
  }, []);

  const mockAvailableStudyTime = { timeAvailable: 40 };

  return (
    <Dialog.Root
      open={isAvailableTimeModalOpen}
      onOpenChange={setIsAvailableTimeModalOpen}
    >
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent isFrial={isFrial}>
            {
              <>
                <TextWrapper>
                  <ModalHeader>
                    <h2>Ajuste sua disponibilidade de tempo</h2>

                    <Dialog.Close asChild>
                      <CloseButton>
                        <IoClose size={24} color={colors.interface.grey300} />
                      </CloseButton>
                    </Dialog.Close>
                  </ModalHeader>

                  {isFrial ?
                    <FrialDescriptionBox>
                      <p>
                        Flexível e personalizado, o Medtrack ajusta seu cronograma para que você possa manter o foco nas prioridades, <b>mesmo com uma agenda variável.</b>
                      </p>
                      <FrialTimeAvailabilityImage />
                    </FrialDescriptionBox>
                    :
                    <p>
                      Fique a vontade para ajustar seu tempo de estudos conforme a
                      disponibilidade da sua semana. Lembre-se de manter sempre
                      atualizado para que você esteja sempre no caminho certo.
                    </p>
                  }
                </TextWrapper>

                {!isFrial && <Hr />}

                <MedTrackTooltip
                  isHidden={!isFrial}
                  width="189px"
                  variant="frialTimeAvailability"
                  text="O cronograma de estudos do MedTrack é ajustado de acordo com as horas que você tem disponível."
                >
                  <SliderWrapper isFrial={isFrial}>
                    <p>
                      Defina sua disponibilidade de horas por semana para estudar:
                    </p>
                    <Slider
                      defaultValue={isFrial ? mockAvailableStudyTime.timeAvailable : availableStudyTime?.timeAvailable || 0}
                      availableHours={isFrial ? mockAvailableStudyTime.timeAvailable : availableStudyTime?.timeAvailable || 0}
                      onChange={onChangeAvailableTime}
                    />

                    <span>
                      {updatedAt && `Atualizado dia ${updatedAt}`}
                      {nextDateToUpdate &&
                        ` - Válido a partir de ${nextDateToUpdate}`}
                    </span>
                  </SliderWrapper>
                </MedTrackTooltip>

                <Infobox variant="lightGrey">
                  {isFrial ?
                    <>
                      Ao se matricular, você desbloqueia essa funcionalidade integralmente.
                    </> :
                    <>
                      Ao definir uma nova disponibilidade de horas de estudos semanal
                      suas tarefas serão automaticamente ajustadas conforme sua
                      disponibilidade a partir da semana seguinte.
                    </>
                  }
                </Infobox>


                {isFrial ?
                  <StyledEMRButton
                    mode="standard"
                    fullSized
                    onClick={handleRedirectToCheckout}
                    style={{ opacity: 0.6, marginTop: 16 }}
                  >
                    <IoLockClosed size={24} />
                    Definir disponibilidade
                  </StyledEMRButton>
                  :
                  <>
                    <Hr />
                    <ButtonContainer>
                      <Dialog.Close asChild>
                        <StyledEMRButton mode="cancel" fullSized>
                          Cancelar
                        </StyledEMRButton>
                      </Dialog.Close>

                      <StyledEMRButton
                        mode="standard"
                        fullSized
                        disabled={isLoading}
                        onClick={onUpdateAvailableTime}
                      >
                        {isLoading ? (
                          <StyledSpinner inline size="small" />
                        ) : (
                          'Salvar alterações'
                        )}
                      </StyledEMRButton>
                    </ButtonContainer>
                  </>
                }
              </>
            }
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal >
    </Dialog.Root >
  );
}
