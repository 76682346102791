import styled from "styled-components";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Tab } from "semantic-ui-react";
import { trackCreateNewMockTemplateEvent } from "../../../services/mixpanel/events/trackCreateNewMockTemplateEvent";
import { EMRButton } from "../../componentsV2/Button";
import { fetchMedtrackMocks } from "../../processes/mockProccess";
import { getCurrentUser, isExpiredUser } from "../../selectors/userSelectors";
import { colors } from "../library/theme";
import Page from "../library/Page";
import MockTemplatesExercisesContainer from "./MockTemplatesExercisesContainer";
import { connect } from "react-redux";
import MockTemplatesHighlightedContainer from "./MockTemplatesHighlightedContainer";
import MockTemplatesIntelligentContainer from "./MockTemplatesIntelligentContainer";
import MockTemplatesMedtrackContainer from "./MockTemplatesMedtrackContainer";
import MocksContainer from "./MocksContainer";
import Link from "../library/Link";
import { IoHeartSharp } from "react-icons/io5";

export const TabWrapper = styled.div`
  @media (max-width: 600px) {
    overflow-y: clip;

    .ui.green.pointing.secondary.menu {
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-y: clip;
    }
    .ui.green.pointing.secondary.menu ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-left: auto;
  width: fit-content;
  @media (max-width: 767px) {
    margin: none;
    width: 100%;

    > button {
      width: 100%;
    }
  }
`;

const ButtonFavoriteQuestions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  gap: 8px;
  padding: 0px 24px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 4px;
  background-color: #e1e8ed;
  cursor: pointer;
  color: #606a71;

  > svg {
    fill: #35bd78;
  }

  &:hover {
    background-color: #c2ced6;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: 767px) {
    gap: 16px;
    flex-direction: column-reverse;
  }
`;

const menuOptions = {
  color: colors.semantic,
  secondary: true,
  pointing: true,
};

const Tooltip = styled.div`
  color: #606a71;
  display: none;
  width: 200px;
  height: fit-content;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  border-radius: 4px;
  border: solid 1px #c2ced6;
  position: absolute;
  padding: 8px;
  z-index: 999;
  background-color: #edf0f2;
  text-align: justify;
  transform: translateY(-50%);
`;

const TooltipContainer = styled.span`
  position: relative;
  display: inline-flex;

  ${Tooltip} {
    display: ${({ forceMount }) => (forceMount ? "block" : "none")};
  }

  :hover {
    ${Tooltip} {
      display: block;
    }
  }
`;

const DisabledTab = styled.div`
  color: #a0a4a8;
  cursor: not-allowed;
  position: relative;
  display: inline-block;
  padding: 4px 16px;
`;



function MockPage({
  children,
  history,
  user,
  isFrialUser,
  expiredUser,
  dispatch,
  medtrackEnabled,
}) {
  const reactRouterHistory = useHistory();
  const [isMedtrackEnabled, setIsMedtrackEnabled] = useState(medtrackEnabled);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tab = urlParams.get("tab");

  const panes = [
    {
      menuItem: "Meus Simulados",
      tabName: "my",
      render: () => (
        <Tab.Pane as="div">
          <MocksContainer />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        isFrialUser ? (
          <TooltipContainer>
            <DisabledTab>Simulados Gerais</DisabledTab>
            <Tooltip forceMount={true}>Ao se matricular você desbloqueia essa funcionalidade integralmente.</Tooltip>
          </TooltipContainer>
        ) : (
          "Simulados Gerais"
        )
      ),
      tabName: "general",
      render: () => (
        <Tab.Pane as="div">
          <MockTemplatesHighlightedContainer />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        isFrialUser ? (
          <TooltipContainer>
            <DisabledTab>Simulados de Fixação</DisabledTab>
            <Tooltip forceMount={true}>Ao se matricular você desbloqueia essa funcionalidade integralmente.</Tooltip>
          </TooltipContainer>
        ) : (
          "Simulados de Fixação"
        )
      ),
      tabName: "fixation",
      render: () => (
        <Tab.Pane as="div">
          <MockTemplatesExercisesContainer />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Simulados Inteligentes",
      tabName: "intelligent",
      render: () => (
        <Tab.Pane as="div">
          <MockTemplatesIntelligentContainer />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        isFrialUser ? (
          <TooltipContainer>
            <DisabledTab>Simulados Medtrack</DisabledTab>
            <Tooltip forceMount={true}>Ao se matricular você desbloqueia essa funcionalidade integralmente.</Tooltip>
          </TooltipContainer>
        ) : (
          "Simulados Medtrack"
        )
      ),
      tabName: "medtrack",
      render: () => (
        <Tab.Pane as="div">
          <MockTemplatesMedtrackContainer />
        </Tab.Pane>
      ),
    },
  ];

  const tabs = {
    my: 0,
    general: 1,
    fixation: 2,
    intelligent: 3,
    medtrack: 4,
  };

  if (!medtrackEnabled) {
    delete tabs.medtrack;
  }

  const panesToRender = panes.filter((p, i) => i !== 4 || medtrackEnabled);

  const initialActivePane = tabs[tab] ?? 0;

  const [activePane, setActivePane] = useState(initialActivePane);

  const handleTabChange = useCallback(
    (e, { activeIndex }) => {
      if (
        (isFrialUser && (activeIndex === 1 || activeIndex === 2 || activeIndex === 4)) ||
        activeIndex === undefined
      ) {
        return;
      }

      const tabName = panes[activeIndex].menuItem;

      dispatch({
        type: "SET_ACTIVE_MOCK_TAB_NAME",
        activeMockTabName: tabName,
      });

      reactRouterHistory.push({
        search: `?tab=${panes[activeIndex].tabName}`,
      });
      setActivePane(activeIndex);
    },
    [isFrialUser]
  );

  function handleClick() {
    trackCreateNewMockTemplateEvent({
      eventType: "newMockModal",
      mockTemplateType: panes[activePane].menuItem,
      isFrialUser,
    });
    reactRouterHistory.push("/mocks/new");
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mockType = urlParams.get("type");
    fetchMedtrackMocks({ medtrackType: mockType }).then((mocks) => {
      const shouldShow = mocks.some((mock) => mock.progress < 1);

      if (!shouldShow) {
        setIsMedtrackEnabled(false);
        delete tabs.medtrack;
      }
    });
    if (mockType === "smart") setActivePane(3);
  }, []);

  if (expiredUser) return null;

  return (
    <Page
      title="Simulados"
      pageIsIntelligent={activePane == 3 ? true : false}
      history={history}
      action={
        <ButtonContainer>
          {activePane === 0 && (
            <ButtonWrapper>
              <EMRButton onClick={() => handleClick()} fontSize="lg" mediumWeight>
                + Criar Novo Simulado
              </EMRButton>
            </ButtonWrapper>
          )}
        </ButtonContainer>
      }
    >
      <TabWrapper>
        <Tab
          menu={menuOptions}
          panes={panesToRender}
          activeIndex={activePane}
          onTabChange={handleTabChange}
        />
      </TabWrapper>
      {children}
    </Page>
  );
}

function mapStateToProps(state, ownProps) {
  const expiredUser = isExpiredUser(state);
  const isFrialUser = state?.isFrialUser;
  const user = getCurrentUser(state);

  return {
    expiredUser,
    isFrialUser,
    user,
    medtrackEnabled: state.isMedTrackFirstAccess.medtrackEnabled,
    ...ownProps,
  };
}

export default connect(mapStateToProps)(MockPage);
